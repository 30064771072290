.background{
  position: fixed;
  z-index: -1;
  top:0;
  left:0;
  right : 0;
  bottom: 0;
  opacity: 1;
  transition: all .8s ease-in-out;

  &.fade{
    opacity: .3;
  }

  .media{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: saturate(30%);

    >video{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

  }
}
