@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';

.home{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-color;

  @media screen and (max-height: 950px){
    align-items: flex-start;
  }

  .container{
    margin-bottom: $margin-lg;
    max-width: 800px;
    min-height: 100%;
    justify-content: space-between;

    p{
      text-align: center;
      margin-top: 0;
      margin-bottom: $margin-md;
    }

    >div{
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
      text-align: center;

      @media screen and (min-height: 750px){
        padding-top: 60px;
      }

      .container-btn-discover{
        margin: $margin-md 0 0 0;
        a{
          line-height: 30px;
        }
      }

      .learn-more{
        position: relative;
        text-decoration: none;
        color : $primary-color;
        padding-bottom: 3px;
        &:after{
          content: "";
          position: absolute;
          display: block;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: $primary-color;
        }
      }

    }

    @include logo();

  }

  .deftech{
    margin: $margin-lg 0 $margin-sm 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      margin-right: $margin-md;
    }
  }

  .general-conditions{
    padding: $margin-sm $margin-md;
    color : $white;
  }

}
