@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.sources{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;

  @include backAnnexe;

  h1{
    font-family: "Gudea";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
  }

  .container{
    padding: $margin-md;
    box-sizing: border-box;
    transition: opacity .5s ease-in-out;

    max-width: 1200px;
    height: 100%;

    @media screen and ( min-width: #{$screen-sm }){
      padding: 100px $margin-md;
    }

    .container-col{
      display: flex;
      flex-direction: column;
      >div{
        width: 100%;
      }

      @media screen and ( min-width: #{$screen-sm }){
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;

        >div{
          width: 30%;
        }
      }

      .list-studies{
        padding: 0;
        li{
          list-style-type: none;
          label{
            color : $primary-color;
            font-weight: 700;
          }

          a{
            color : $primary-color;
          }

          p{
            margin-top: 0;
          }

        }
      }

    }

  }
}
