@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Gudea:ital,wght@0,400;0,700;1,400&display=swap');

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
  color : white;
}

html,body,#root,.App,.App>div{
  height: 100%;
  width: 100%;
}

// button{
//   cursor: pointer;
//   border: none;
//   padding: 15px 20px;
//   min-width: 230px;
//   font-weight: bold;
//   font-family: "Montserrat-Bold";
//   letter-spacing: 1.5px;
//   text-transform: uppercase;
//   color : $title-color;
//   background: $white;
//   height: 45px;
//   border-radius: calc(45px / 2);
//   margin-bottom: $margin-sm;
//   outline:none;
//
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//
// }

h1,h2,h3{
  font-family: "Gudea", sans-serif;
}

a,p,label{
  font-family: "Gudea", sans-serif;
  font-size: $regular;
  line-height: calc(#{$regular} + 3px);
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  padding: 0 $margin-md;
}
