@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.story{

  .story-controls{
    position: fixed;
    z-index: 10;
    bottom: 0;

    display: flex !important;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: $margin-sm;
    box-sizing: border-box;
    background: rgba(31, 25, 56,.8);

    @media screen and (min-width: #{$screen-md}){
      width: auto;
      background-color: transparent;
    }

    svg{
      font-size: 24px;
      padding: 5px;
      cursor: pointer;

      path{
        transition: all .3s ease-in-out;
      }

      &:hover{
        path{
          fill : $primary-color;
        }
      }
    }

    &.hide-on-mobile{
      @include hide-on-mobile();
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      svg{
        font-size: 32px;
        margin: 0 10px;
      }

    }

    &.only-on-mobile{
      @include only-on-mobile();
    }

  }

}
