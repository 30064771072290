@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.intro{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 600px){
    align-items: flex-start;
    // display: none;
  }

  .background{
    position: fixed;
    top:0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../assets/images/background-intro.png");
    opacity: .2;
    filter: saturate(30%);
  }

  .container{
    position: relative;
    z-index: 1;

    @include logo();

    label{
      font-weight: 700;
    }

    p{
      font-family: "Gudea";
      text-align: center;
      color : $white;
      margin-bottom: $margin-sm;
    }

    label:nth-child(4){
      margin-bottom: $margin-sm;
    }

  }

  .intro-controls{
    position: fixed;
    z-index: 10;
    height: auto !important;
    bottom: 0;
    left:calc(100% - 45px);
    padding: $margin-sm;
    box-sizing: border-box;

    @media screen and (min-width: #{$screen-sm}){
      left:50%;
    }

    svg{
      font-size: 24px;
      padding: 5px;
      cursor: pointer;
      transform: translateX(-50%);



      @media screen and (min-width: #{$screen-sm}){
        font-size: 32px;
      }

      path{
        transition: all .3s ease-in-out;
        fill : $white;
      }

      &:hover{
        path{
          fill : $primary-color;
        }
      }
    }

  }

}
