@mixin hide-on-mobile(){
  display: inherit;
  @media screen and (max-width: #{$screen-sm}){
    display: none !important;
  }
}

@mixin only-on-mobile(){
  display: inherit;
  @media screen and (min-width: #{$screen-sm}){
    display: none !important;
  }
}

@mixin logo(){
  .logo{
    max-width: 650px;
    img{
      width: 100%;
      max-width: 402px;
    }
  }
}

@mixin backAnnexe(){
  .back-navigation{
    height: auto !important;
    padding: 10px 10px 10px 0px;
    box-sizing: border-box;

    display: inherit;
    @media screen and (min-width: #{$screen-sm}){
      display: none !important;
    }

    img{
      position: relative;
      top: 4px;
      width: 18px;
    }
    label{
      text-transform: uppercase;
      font-family: "Gudea";
      font-weight: bold;
      font-size: 15px;
    }
  }
}
