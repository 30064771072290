@import '../../scss/variables.scss';

.textarea{
  display: block;
  outline: none;
  background: transparent;
  border : none;
  border-bottom: solid 2px rgba(255,255,255,.3);
  color : rgba(255,255,255,.3);
  font-family: "Gudea", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-align: left;
  resize: none;

  margin: 0 0 $margin-sm 0;

  &:focus{
    color : white;
    border-bottom: solid 2px $primary-color;
  }

}
