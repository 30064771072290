@import '../../../scss/variables.scss';

.navigation-annexes{
  .container{
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    padding: 70px $margin-md $margin-md $margin-md;
    box-sizing: border-box;
    text-align: center;

    p{
      text-align: center;
    }

    a{
      font-family: "Gudea";
      font-weight: 700;
      color: $white;
      text-align: center;
      text-transform: uppercase;
    }

  }

  .navigation-annexes-controls{
    position: fixed;
    z-index: 10;
    height: auto !important;
    bottom: 0;
    right:10px;
    padding: $margin-sm;
    box-sizing: border-box;

    svg{
      font-size: 24px;
      padding: 5px;
      cursor: pointer;

      path{
        transition: all .3s ease-in-out;
        fill : $white;
      }

      &:hover{
        path{
          fill : $primary-color;
        }
      }
    }

  }

}
