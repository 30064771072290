/*neon Button*/
@import '../../scss/variables.scss';

.button{
  font-family: 'Gudea', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: calc(23px + 10px);
  color: #fff;
  text-align: center;
  margin: 12px;
  text-decoration: none;
  position: relative;
  padding: 0px 18px;
  overflow: hidden;
  transition: 0.2s all;
  background: rgba(31, 25, 56, 0.7);
  border: solid 2px $primary-color;
  cursor: pointer;
  min-width: 200px;

  @media screen and (min-width: #{$screen-sm}){
    padding: 5px 18px;
  }

  p{
    font-family: 'Gudea', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: calc(23px + 10px);
  }
}

.button:hover{
  background:$primary-color;
  color: #000;
  box-shadow: 0px 0px 5px $primary-color, 0px 0px 20px $primary-color, 0px 0px 40px $primary-color;
}
