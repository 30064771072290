@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.end{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;

  h2{
    font-family: "Gudea";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: $margin-sm;
  }

  .container{
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 70px;
    padding-bottom: 70px;
    transition: opacity .5s ease-in-out;

    &.loading{
      opacity: .8;
    }

    p{
      font-family: "Gudea";
      color : $white;
      margin-bottom: $margin-lg;
    }

    label{
      font-family: "Gudea";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
    }

    .inline-fields{
      width: 100%;
      margin-bottom: $margin-sm;
      >div{
        flex-direction: column;
        display: flex;
        align-items: flex-start;

        >input{
          max-width: 300px;
          width: 100%;
          min-width: inherit;
        }

        .container-select{
          max-width: 300px;
          width: 100%;
          min-width: inherit;
          >input{
            width: 100%;
          }
        }

        @media screen and (min-width: 670px){
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          >input{
            min-width: 230px;
          }

          .container-select{
            min-width: 230px;
            >input{
              width: 100%;
            }
          }

        }



      }
    }

    .container-input-rate{
      max-width: 350px;
      width: 100%;
    }

    .container-textarea{
      max-width: 450px;
      width: 100%;
      >textarea{
        width: 100%;
      }
    }

  }

  .end-controls{
    position: fixed;
    z-index: 10;
    height: auto !important;
    bottom: 0;
    right:10px;
    padding: $margin-sm;
    box-sizing: border-box;

    svg{
      font-size: 24px;
      padding: 5px;
      cursor: pointer;

      @media screen and (min-width: #{$screen-sm}){
        font-size: 32px;
      }

      path{
        transition: all .3s ease-in-out;
        fill : $white;
      }

      &:hover{
        path{
          fill : $primary-color;
        }
      }
    }

  }

  .container-submit{
    >div{
      max-width: 200px;
      margin: $margin-md 0;
    }
  }

}
