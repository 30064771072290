@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.intro{
  display: flex;
  align-items: center;
  justify-content: center;
  .container{

    @include logo();

    p{
      font-family: "Gudea";
      text-align: center;
      color : $white;
      margin-bottom: $margin-lg;
    }

  }

  .intro-controls{
    position: fixed;
    z-index: 10;
    height: auto !important;
    bottom: 0;
    left:50%;
    padding: $margin-sm;
    box-sizing: border-box;

    svg{
      font-size: 24px;
      padding: 5px;
      cursor: pointer;
      transform: translateX(-50%);

      @media screen and (min-width: #{$screen-sm}){
        font-size: 32px;
      }

      path{
        transition: all .3s ease-in-out;
        fill : $white;
      }

      &:hover{
        path{
          fill : $primary-color;
        }
      }
    }

  }

}
