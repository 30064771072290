@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.resultat{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;

  @include backAnnexe;
  .back-navigation{
    margin-top: 10px;
  }

  .share{
    position: fixed;
    bottom: 27px;
    right: 90px;
    a{
      margin-left: 10px;
      cursor: pointer;
    }
    a:hover{
      opacity:0.7
    }
  }

  .container{
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-top: $margin-sm;
    padding-bottom: 100px;
    box-sizing: border-box;
    transition: opacity .5s ease-in-out;

    max-width: 1200px;
    height: 100%;
    overflow-y: auto;

    @media screen and ( min-width: #{$screen-sm}){
      padding-top: 100px;
    }

    @media screen and ( min-width: #{$screen-md}){
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }

    .col{
      width: 100%;
      @media screen and ( min-width: #{$screen-md}){
        width: 50%;
        max-height: 100%;
        overflow-y: auto;
      }
    }

  }

  .container-three{
    width: 100%;
    margin-bottom: $margin-md;

    @media screen and ( min-width: #{$screen-md}){
      width: 45%;
      margin-bottom: 0;
    }

    .group{
      position: relative;
      display: flex;

      .container-label{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 200px;
        label{
          text-align: center;
          width: 100%;
        }
      }

      >div:first-child{
        width: 65%;
        min-width : 300px;
        border-bottom: 1px solid rgba(0, 255, 224, 0.15);
      }

      >div{
        flex: 1;
        padding: $margin-sm $margin-sm;
        @media screen and ( min-width: #{$screen-md}){
          padding: $margin-sm 0;
        }
      }

      .line{
        display: flex;
        align-items: center;
        justify-content: center;

        .node{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 68px;
          height: 68px;
          min-width: 68px;
          min-height: 68px;
          border-radius: 34px;
          background: rgba(31, 25, 56, 0.81);
          border: 2px solid rgba(110, 228, 209, 0.22);
          box-sizing: border-box;
          margin: $margin-sm $margin-md;

          &.positive{
            font-family: "Gudea";
            font-weight: 700;
            background-color: $primary-color;
            color : $background-color;
            box-shadow: 0px 0px 6.49087px $primary-color;
          }

          &.negative{
            font-family: "Gudea";
            font-weight: 700;
            background-color: #FC5185;
            box-shadow: 0px 0px 8px #FC5185;
          }

        }

      }

    }


  }

  .key-choice{
    margin-bottom: $margin-md;
    label{
      font-weight: 700;
    }

    // text anna
    >span>p{
      margin: $margin-sm 0 $margin-sm 0;
    }

    label,p{
      font-size: $regular;
    }

    .text-answer{
      font-weight: 700;
      color : $primary-color;
      margin: 5px 0;
      &.positive{
        color : $primary-color;
      }
      &.negative{
        color : #FC5185;
      }
    }

    .answers{
      p:first-child{
        &.positive{
          >span{
            font-weight: 700;
            color : $primary-color;
          }
        }
        &.negative{
          >span{
            font-weight: 700;
            color : #FC5185;
          }
        }
      }

      ul{
        padding: 0 0 0 20px;
        margin-top: 0;
        &.positive{
          li:first-child{
            font-weight: 700;
            color : $primary-color;
          }
        }
        &.negative{
          li:first-child{
            font-weight: 700;
            color : #FC5185;
          }
        }
      }

      p{
        margin: 5px 0;
        img{
          position: relative;
          top: -2px;
          margin-right: $margin-xs;
        }
        >span{
          // text-transform: uppercase;
        }
      }
    }


  }

}



// font-family: Gudea;
// font-size: 14px;
// font-style: normal;
// font-weight: 700;
// line-height: 16px;
// letter-spacing: 0em;
// text-align: left;
//
// font-family: Gudea;
// font-size: 14px;
// font-style: normal;
// font-weight: 400;
// line-height: 16px;
// letter-spacing: 0em;
// text-align: left;
