@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.navigation{
  position: fixed;
  top: 20px;
  right: 30px;
  display: flex;
  padding: $margin-sm;
  margin: 0;
  width: 330px;
  justify-content: space-between;

  @media screen and (max-width: #{$screen-sm}){
    display: none !important;
  }

  li{
    list-style-type: none;
    a{
      position: relative;
      font-family: "Gudea";
      font-size: $regular;
      font-weight: 700;
      color: $white;
      text-decoration: none;

      &.active:after{
        content : "";
        position: absolute;
        display: block;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background-color: $primary-color;

      }

    }

  }
}

.annexes-controls{
  position: fixed;
  z-index: 10;
  height: auto !important;
  bottom: 0;
  right:10px;
  padding: $margin-sm;
  box-sizing: border-box;
  width: auto !important;

  svg{
    font-size: 24px;
    padding: 5px;
    cursor: pointer;

    @media screen and (min-width: #{$screen-sm}){
      font-size: 32px;
    }

    path{
      transition: all .3s ease-in-out;
      fill : $white;
    }

    &:hover{
      path{
        fill : $primary-color;
      }
    }
  }

}
