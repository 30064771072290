/*neon Button*/
@import '../../scss/variables.scss';

.container-btn{
  position: relative;
  margin: 20px;
  min-height: 47px;
  text-align: center;
  .shadow{
    position: absolute;
    z-index: 0;
    pointer-events: none;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    display: block;
    background: transparent;
    border: solid 2px $primary-color;
    box-shadow: 0px 0px 2px $primary-color, 0px 0px 4px $primary-color, 0px 0px 8px $primary-color;
  }

  &:hover{
    .shadow{
      animation-name: hoverEffect;
      animation-duration: 1.4s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes hoverEffect {
  0% {
    border: solid 0px transparent;
    box-shadow: 0px 0px 2px $primary-color, 0px 0px 4px $primary-color, 0px 0px 8px $primary-color;
  }

  25% {
    box-shadow: 0px 0px 0px $primary-color, 0px 0px 0px $primary-color, 0px 0px 0px $primary-color;
  }

  75% {
    background: transparent;
    border: solid 0px transparent;
    box-shadow: 0px 0px 0px $primary-color, 0px 0px 0px $primary-color, 0px 0px 0px $primary-color;
  }

  100% {
    border: solid 2px $primary-color;
    background: linear-gradient(180deg, #E786FF 0%, #FF9D81 100%);
    box-shadow: 0px 0px 5px $primary-color, 0px 0px 20px $primary-color, 0px 0px 40px $primary-color;
  }

}

}

.a-n1{
  position: relative;
  z-index: 1;
  display: inline-block;
  font-family: 'Gudea', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
  padding: 15px 30px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s all;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;

  p{
    margin: 0 !important;
  }

}
.a-n1 span{
    position: absolute;
    display: block;
}
.a-n1 span:nth-child(1){
 top:0px;
 left:-100%;
 width: 100%;
 height: 2px;
 background: linear-gradient(90deg,transparent,$primary-color )
}

.a-n1:hover span:nth-child(1){
    left:100%;
    transition: 0.5s;
}


.a-n1 span:nth-child(3){
 bottom:0px;
 right:-100%;
 width: 100%;
 height: 2px;
 background: linear-gradient(270deg,transparent,$primary-color )
}

.a-n1:hover span:nth-child(3){
    right:100%;
    transition: 0.5s;
    transition-delay: 0.5s;
}



.a-n1 span:nth-child(2){
 top:-100%;
 right:0;
 width:  2px;
 height: 100%;
 background: linear-gradient(180deg,transparent,$primary-color )
}

.a-n1:hover span:nth-child(2){
    top:100%;
    transition: 0.5s; transition-delay: 0.25s;
}

.a-n1 span:nth-child(4){
 bottom:-100%;
 left:0;
 width:  2px;
 height: 100%;
 background: linear-gradient(360deg,transparent,$primary-color )
}

.a-n1:hover span:nth-child(4){
    bottom:100%;
    transition: 0.5s;  transition-delay: 0.75s;
}
