@import '../../scss/variables.scss';

.step{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container{
    max-height: 100%;
    box-sizing: border-box;
  }

  .choices{

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    max-width: 800px;
    width: 100%;
    max-height: 100%;
    padding: 80px $margin-sm;
    box-sizing: border-box;


    @media screen and (min-width: #{$screen-md}){
      padding: 0 0 0 0;
    }

    // button{
    //   margin-right: $margin-xs;
    //   margin-left: $margin-xs;
    //   max-width: 230px;
    //   height: auto;
    // }

    .answers{
      display: inline-flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      a{
        margin-left: 0;
        margin-right: 0;
      }
    }

  }

}
