@import "../../scss/variables.scss";

.dropdown-indicator{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  cursor: pointer;

  &.reverse:after{
    transform: rotate(225deg) ;
  }

  &:not(.reverse):after{
    transform: rotate(45deg) ;
  }

  &:hover:after{
    border-color : $primary-color;
  }

  &:after{
    content : '';
    position: relative;
    top:-3px;
    width: 6px;
    height: 6px;
    border-right: solid 2px $primary-color;
    border-bottom: solid 2px $primary-color;
  }
}

.clear-indicator{
  position: relative;
  height: 20px;
  width: 20px;
  &:hover:after,
  &:hover:before{
    background-color : $primary-color;
  }

  &:after,&:before{
    content : '';
    position: absolute;
    top:50%;
    left:50%;
    width: 12px;
    height: 2px;
    transform-origin: center;
    background-color: $white;
  }

  &:before{
    transform-origin: center;
    transform: translate(-50%,-50%) rotate(45deg) ;
  }

  &:after{
    transform-origin: center;
    transform: translate(-50%,-50%) rotate(-45deg) ;
  }
}
