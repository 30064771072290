// COLORS
$primary-color : #00FFE0;
$secondary-color : #0F3036;
$background-color : #1F1938;
$title-color : #8D6187;
$white : #FFFFFF;

// METRICS

$margin-xs : 10px;
$margin-sm : 15px;
$margin-md : 30px;
$margin-lg : 45px;

$regular : 16px;
$medium : 20px;
$large : 26px;

$title : 34px;

// MEDIAQUERY

$screen-xs : 360px;
$screen-sm : 767px;
$screen-md : 920px;
$screen-lg : 1024px;
$screen-xxl : 1224px;

// FONTS

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Montserrat-Medium";
	src: url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Light";
	src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
}
