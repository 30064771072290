@import '../../scss/variables.scss';

.modal-sound{
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 150px !important;
  height: 150px !important;
  background-color: white;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg{
    font-size: 100px;
    path {
      fill : $primary-color;
    }
  }
}

.modal-overlay{
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background-color: rgba(200,200,200,.2);
}
