@import '../../scss/variables.scss';

.input-text{
  display: block;
  outline: none;
  background: transparent;
  border : none;
  border-bottom: solid 2px rgba(255,255,255,.3);
  color : rgba(255,255,255,.3);
  font-family: "Gudea", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-align: left;
  margin: 10px 0;


  &:focus{
    color : white;
    border-bottom: solid 2px $primary-color;
  }

  &.error{
    border : solid 1px red;
  }
}
