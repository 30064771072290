@import '../../scss/variables.scss';

.question p{
  font-family: "Gudea";
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color : $white;
  margin-top: 0;
  max-width: 500px;
  padding: 0 $margin-md;
  margin-bottom: $margin-sm;
}
