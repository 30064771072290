.aura{
  position: fixed;
  z-index: 0;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: .8;
  filter: saturate(80%);

  >video{
    width: 100%;
    height: 100%;
    transform: translate(0,0);
    object-fit: cover;
  }
}
