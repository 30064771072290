@import '../../scss/variables.scss';

.technocast{

  .logo-header{
    position: absolute;
    top:7px;
    left:7px;
  }

  .container{
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    padding: 90px $margin-md $margin-md $margin-md;
    box-sizing: border-box;

    h2{
      font-family: "Gudea";
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: $margin-sm;
    }

    label{
      display: inline-block;
      font-family: "Gudea";
      font-weight: bold;
      margin-bottom: $margin-sm;
    }

    p{
      color : $white;
      margin-bottom: $margin-md;
      margin-top: 0;
    }

    a{
      color:$primary-color
    }

  }

}
