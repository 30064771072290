@import '../../scss/variables.scss';


.subtitles,
.subtitles-centered{
  p{
    // display: none;
    font-family: 'Gudea', sans-serif;
    font-weight: 400;
    font-size: $medium;
    text-align: center;
    line-height: 30px;
    box-sizing: border-box;
  }
}

.subtitles-centered{
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .container-scroll{
    overflow: auto;
    max-height: 100%;
    .content{
      margin: 0 auto;
      max-width: 700px;
      box-sizing: border-box;
      max-height: 100%;
      padding: 60px $margin-md 60px $margin-md;

      @media screen and (min-width: #{$screen-md}){
        padding: 0 $margin-md $margin-sm $margin-md;
      }

    }
  }



}

.subtitles{
  position: fixed;
  z-index: 9;
  bottom: 70px;
  width: 100%;
  background: rgba(31, 25, 56,.8);

  @media screen and (max-width: #{$screen-md}){
    bottom: 0;
  }

  .container-scroll{
    overflow: auto;
    // max-height: 260px;

    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    &::-webkit-scrollbar {
      display: none;
      width: 0px; /* For Chrome, Safari, and Opera */
    }

  }

  &:before{
    content : "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100px;
    transform: translateY(-100%);
    background: linear-gradient(180deg, rgba(31, 25, 56,0) 0%, rgba(31, 25, 56,.8) 100%);
  }

  &:after{
    content : "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100px;
    top:100%;
    background: rgba(31, 25, 56,.8);
  }


  .content{
    margin: 0 auto;
    max-width: 700px;
    box-sizing: border-box;
    max-height: 100%;
    padding: 0 $margin-md 60px $margin-md;

    @media screen and (min-width: #{$screen-md}){
      padding: 0 $margin-md $margin-sm $margin-md;
    }

  }
}
