@import '../../scss/variables.scss';

.checkboxes{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: $margin-sm 0 $margin-md 0;

  .container-checkbox{
    margin-right: $margin-md;

    .checkbox{
      position: relative;
      top:3px;
      display: inline-block;
      margin-right: 7px;
    }

    label{
      display: inline-block;
      font-family: "Gudea";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;

    }

  }

}
