@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.credits{
  @include backAnnexe;

  .container{
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    padding: $margin-md;
    box-sizing: border-box;

    @media screen and ( min-width: #{$screen-sm }){
      padding: 100px $margin-md $margin-md $margin-md;
    }

    h2{
      font-family: "Gudea";
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: $margin-sm;
    }

    label{
      display: inline-block;
      font-family: "Gudea";
      font-weight: bold;
    }

    p,a{
      color : $white;
      margin-bottom: $margin-md;
      margin-top: 0;
    }

    a{
      color:$primary-color
    }

  }
}
